<template>
    <section id="gallery">
        <v-container class="fill-height px-10 py-10 pl-10">
            <v-row align="center" justify="center">
                <v-card color="basil">
                    <v-card-title class="text-center justify-center">
                        <h3
                            class="font-weight-bold text-h6 text-md-h2 text-xl-h3 text-truncate basil--text"
                        >
                            PRODUCT GALLERY
                        </h3>
                    </v-card-title>

                    <v-tabs v-model="tab" background-color="transparent" grow>
                        <v-tab v-for="item in items" :key="item">
                            {{ item }}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="item in items" :key="item">
                            <v-card color="basil" flat>
                                <v-card-text>
                                    <v-row>
                                        <v-col
                                            v-for="n in 12"
                                            :key="n"
                                            cols="12"
                                            md="4"
                                            lg="4"
                                            sm="12"
                                        >
                                            <v-img
                                                :src="
                                                    require(`@/assets/product-${n}.jpeg`)
                                                "
                                                class="grey lighten-2"
                                                max-width="500"
                                                height="250"
                                            >
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-row>
        </v-container>
    </section>
</template>
<script>
export default {
    data() {
        return {
            tab: null,
            items: [
                'All',
                'Food Chemicals',
                'Solvents',
                'Industrial Chemicals',
            ],
            all_images: [
                {
                    path: '1',
                    text1: 'Onyeuwaoma John Chibuikem',
                    text2: '',
                },
                {
                    path: '2',
                    text1: 'Onyeuwaoma John Chibuikem',
                    text2: '',
                },
                {
                    path: '3',
                    text1: 'Onyeuwaoma John Chibuikem',
                    text2: '',
                },
                {
                    path: '4',
                    text1: 'Onyeuwaoma John Chibuikem',
                    text2: '',
                },
                {
                    path: '5',
                    text1: 'Onyeuwaoma John Chibuikem',
                    text2: '',
                },

                {
                    path: '6',
                    text1: 'Onyeuwaoma John Chibuikem',
                    text2: '',
                },
                {
                    path: '7',
                    text1: 'Onyeuwaoma John Chibuikem',
                    text2: '',
                },
                {
                    path: '8',
                    text1: 'Onyeuwaoma John Chibuikem',
                    text2: '',
                },
                {
                    path: '9',
                    text1: 'Onyeuwaoma John Chibuikem',
                    text2: '',
                },
                {
                    path: '10',
                    text1: 'Onyeuwaoma John Chibuikem',
                    text2: '',
                },
            ],
        };
    },
};
</script>
<style scoped>
/* Helper classes */
.basil {
    background-color: #fffbe6 !important;
}
.basil--text {
    color: #0b2571 !important;
}
#gallery {
    background-color: #0b2571 !important;
}
</style>
